<template>
  <!-- 寄语 -->
  <div class="evalute">
    <topBar
      ref="topBar"
      :tabs="tabList"
      @tabNum="tabNum"
      class="topBars"
    ></topBar>

    <div class="addEvalute" v-if="currentIndex == 0">
      <van-form @submit="onSubmit" style="margin-top: 20px">
        <van-field
          v-model="form.Content"
          rows="3"
          type="textarea"
          label="寄语内容"
          placeholder="请填写寄语内容"
          :rules="[{ required: true }]"
          clearable
          required
          show-word-limit
          maxlength="200"
        />

        <!-- <van-field name="AccessoryPostList" label="照片">
					<template #input>
						<van-uploader v-model="form.AccessoryPostList" :preview-full-image="false" accept="*"
							:max-count="9" @click-preview="review" :before-read="beforeRead" />
					</template>
				</van-field> -->

        <van-field name="uploadList" label="照片" class="require">
          <template #input>
            <div>
              <van-uploader
                v-model="form.AccessoryPostList"
                @delete="deleteImg"
                :show-upload="false"
                accept="*"
              >
              </van-uploader>
              <span class="imgUpload" @click="chooseImage">+</span>
            </div>
          </template>
        </van-field>

        <!-- 编辑时,须有返回新增icon -->
        <div style="margin: 16px" v-if="tabList[0] == '新增寄语'">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            :loading="saveLoading"
            color="#47AFA7"
            >保存
          </van-button>
        </div>
        <div
          style="margin: 16px; display: flex; justify-content: space-between"
          v-else
        >
          <van-button round block style="width: 46%" @click="back"
            >返回
          </van-button>
          <van-button
            round
            block
            type="info"
            native-type="submit"
            :loading="saveLoading"
            color="#47AFA7"
            style="width: 46%"
            >保存
          </van-button>
        </div>
      </van-form>
    </div>

    <div class="evaluteList" v-else style="flex: 1; overflow: auto">
      <div class="scollView">
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          success-text="刷新成功"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="_getMoreList"
          >
            <div v-for="(item, index) in evaluateList" :key="index">
              <van-tag
                round
                size="medium"
                color="#47AFA7"
                text-color="#000000"
                v-if="item.ClassName"
              >
                {{ item.ClassName }}
              </van-tag>
              <div
                class="my-card"
                v-for="itm in item.EvaluationDtos"
                :key="itm.ID"
              >
                <div class="top">
                  <div class="left">
                    {{ itm.EvaluateType == 2 ? "教师寄语" : "家长寄语" }}
                  </div>
                  <div class="right">
                    <div class="icon">
                      <van-icon name="edit" color="#00aaff" size="16" />
                      <span
                        style="margin-left: 4px; color: #00aaff"
                        @click="editEvlute(itm)"
                        >修改</span
                      >
                    </div>
                    <div class="icon">
                      <van-icon name="delete-o" color="#ff0000" size="16" />
                      <span
                        style="margin-left: 4px; color: #ff0000"
                        @click="deleteEvlute(itm)"
                        >删除</span
                      >
                    </div>
                  </div>
                </div>
                <div class="content">
                  <P>{{ itm.Content }}</P>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/common/topBar/index.vue";

import {
  Form,
  Field,
  Button,
  Uploader,
  Toast,
  Tag,
  Icon,
  List,
  PullRefresh,
  Dialog,
} from "vant";
import wx from "weixin-js-sdk";
export default {
  name: "Evalute",
  components: {
    topBar,
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Toast.name]: Toast,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      userType: window.localStorage.getItem("UserType"),
      currentIndex: 0,
      form: {
        AccessoryPostList: [],
        Content: "", //寄语内容
        ChildrenID: "", //
      },
      saveLoading: false,
      params: {
        page: 0,
        limit: 5,
        childrenID: "",
      },
      evaluateList: [], //列表数据
      loading: false,
      finished: false, // false
      refreshing: false,
      tabList: ["新增寄语", "寄语记录"],
      dialogVisible: false,
      titleList: ["中一班", "小一班"],
    };
  },
  created() {
    document.title = this.userType == 2 ? "教师寄语" : "家长寄语";
  },
  mounted() {
    // 获取ChildrenID
    this.form.ChildrenID = this.$route.query.id;
    //this._getEvaluateList()
    this.getWechatSdk();
  },
  methods: {
    getWechatSdk() {
      let wechaturl = window.location.href.split("#")[0];
      // alert(window.localStorage.getItem('wechaturl'))
      // if (window.localStorage.getItem('wechaturl') !== undefined) {
      //   wechaturl = window.localStorage.getItem('wechaturl').split('#')[0];
      // }
      this.$axios
        .get("/api/WeChat/WeChatAuthorization", {
          url: wechaturl,
        })
        .then((res) => {
          if (res.code === 200) {
            this.wxConfig(res.data[0]);
          } else {
          }
        });
    },
    deleteImg() {},
    wxConfig(result) {
      let _this = this;
      let timestamp = result.timeStamp;
      let noncestr = result.nonceStr;
      let signature = result.signature;
      let appId = result.appId;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        //                                debug : true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: noncestr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名，见附录1
        jsApiList: [
          "onMenuShareTimeline",
          "onMenuShareAppMessage",
          "onMenuShareQQ",
          "previewImage",
          "openLocation",
          "getLocation",
          "chooseImage",
          "uploadImage",
          "downloadImage",
        ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      wx.ready(function () {
        //setSessionStorage("wxready", true);
        console.log("微信已加载完毕！");
      });
      wx.error(function (res) {
        // alert(res)
        console.log(res);
      });
    },
    chooseImage(index, type) {
      let _this = this;
      wx.chooseImage({
        count: 9, // 默认9
        sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res) {
          let localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
          _this.wxImgData(localIds, type);
        },
      });
    },
    wxImgData(localIds, type) {
      let _this = this;
      _this.localIdImgs = [];
      var i = 0;
      var length = localIds.length;
      var upload = function () {
        wx.getLocalImgData({
          localId: localIds[i], // 图片的localID
          success: function (res) {
            Toast.loading({
              message: "上传中...",
              duration: 0,
            });
            let localData = res.localData; // localData是图片的base64数据，可以用img标签显示
            if (localData.indexOf("data:image") != 0) {
              //判断是否有这样的头部
              localData = "data:image/jpeg;base64," + localData;
            }
            localData = localData
              .replace(/\r|\n/g, "")
              .replace("data:image/jgp", "data:image/jpeg"); // 此处的localData 就是你所需要的base64位
            _this.localIdImgs.push(localData);
            if (_this.localIdImgs.length >= length) {
              //alert("_this.localIdImgs.length+" + _this.localIdImgs.length);
              _this.getBase(_this.localIdImgs); //这里就可以拿到所有的base64传给逻辑函数了
            }
            i++; //因为多图片上传采用了这个方法
            i < length && upload();
            // let file = _this.dataURLtoFile(localData);
            // // params.append("request", file);
            // //接口上传
            // alert(file);
            // _this.uploadImg(file);
          },
        });
      };
      upload();
    },
    getBase(base) {
      let _this = this;
      const photoName = `${new Date().getTime()}`;
      base.forEach((element) => {
        Toast.loading({
          message: "上传中...",
          duration: 0,
        });
        let initData = element.replace(/%0A|\s/g, "");
        //这里的base64ToFile就是base64转文件流的方法
        let p = _this.dataURLtoFile(initData, `${photoName}.jpg`);
        _this.uploadImg(p);
      });
    },
    dataURLtoFile(dataurl, filename = "weixin.jpg") {
      var arr = dataurl.split(","),
        //mime = arr[0].match(/:(.*?);/)[1],
        mime = "image/jpeg",
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    // 寄语内容提交
    async onSubmit() {
      this.$axios
        .post(
          this.form.ID
            ? "/api/Evaluation/edit-evaluation"
            : "/api/Evaluation/add-evaluation",
          this.form
        )
        .then((res) => {
          if (res.code == 200) {
            this.saveLoading = false;
            this.$toast.success(res.msg);
            this.$router.back();
          } else {
            this.$toast.fail(e.msg || "操作失败");
          }
        })
        .catch((e) => {
          console.log(e);
          this.saveLoading = false;
          this.$toast.fail(e.msg || "操作失败");
        });
    },
    // 获取寄语列表数据
    _getEvaluateList() {
      this.params.childrenID = this.form.ChildrenID;
      Toast.clear();
      this.$axios
        .get("/api/Evaluation/evaluation-list", this.params)
        .then((res) => {
          if (res.code == 200) {
            if (this.refreshing) {
              this.refreshing = false;
            }
            this.evaluateList.forEach((item) => {
              if (item.ClassName == res.data[0].ClassName) {
                delete res.data[0].ClassName;
              }
            });
            this.evaluateList = this.evaluateList.concat(res.data);
            // 寄语列表总数量
            let arr = [];
            this.evaluateList.forEach((item) => {
              if (item.EvaluationDtos.length > 0) {
                arr = arr.concat(item.EvaluationDtos);
              }
            });
            if (arr.length >= res.count) {
              this.finished = true;
            }
            this.loading = false;
          } else {
            this.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    _getMoreList() {
      this.params.page++;
      this._getEvaluateList();
    },
    onRefresh() {
      this.params.page = 1;
      // 清空列表数据
      this.finished = false;
      this.loading = true;
      this.evaluateList = [];
      this._getEvaluateList();
    },
    editEvlute(itm) {
      this.currentIndex = 0;
      this.tabList = ["编辑寄语", "寄语记录"];
      this.$refs.topBar.tabNum = 0;
      this.$axios
        .get("/api/Evaluation/evaluation-detail?id=" + itm.ID)
        .then((res) => {
          if (res.code == 200) {
            this.form.Content = res.data.Content;
            this.form.AccessoryPostList = res.data.accessoryList;
            this.form.ID = res.data.ID;
            console.log(this.form);
          }
        });
    },
    deleteEvlute(itm) {
      Dialog.alert({
        title: "确认删除?",
        showCancelButton: true,
      }).then(() => {
        // on close
        this.$axios
          .post("/api/Evaluation/delete-evaluation", {
            ID: itm.ID,
          })
          .then((res) => {
            if (res.code == 200) {
              // 删除成功刷新数据
              this.onRefresh();
              this.$toast.success(res.msg || "操作失败");
            }
          });
      });
    },
    // 编辑按钮返回
    back() {
      this.currentIndex = 1;
      this.$refs.topBar.tabNum = 1;
      this.tabList = ["新增寄语", "寄语记录"];
    },
    // 预览
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    uploadImg(file) {
      //alert("进来了");
      let that = this;
      Toast.loading({
        message: "上传中...",
        duration: 0,
      });
      let formData = new FormData();
      formData.append("LastFile", file);
      formData.append("FileName", file.name);

      that.$axios.upload("/api/File/UploadFile", formData).then((res) => {
        Toast.clear();
        if (res.code === 200) {
          //console.log(res)
          that.$toast.success(res.msg);
          that.form.AccessoryPostList.push({
            name: file.name,
            osskey: res.data.osskey,
            uploadUrl: res.data.uploadUrl,
            url: res.data.url,
          });
        } else {
          that.$toast.fail(res.msg || "操作失败");
          return false;
        }
      });
    },
    beforeRead(file) {
      if (file.size / 1024 > 1025) {
        this.$compress.photoCompress(
          file,
          {
            quality: 0.2,
          },
          (base64Codes) => {
            file = this.$compress.dataURLtoFile(base64Codes, file.name);
            Toast.loading({
              message: "上传中...",
              duration: 0,
            });
            let formData = new FormData();
            formData.append("LastFile", file);
            formData.append("FileName", file.name);
            this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
              Toast.clear();
              if (res.code === 200) {
                this.form.AccessoryPostList.push({
                  name: file.name,
                  osskey: res.data.osskey,
                  uploadUrl: res.data.uploadUrl,
                  url: res.data.url,
                });
                this.$toast.success(res.msg);
              } else {
                this.$toast.fail(res.msg || "操作失败");
                return false;
              }
            });
          }
        );
      } else {
        Toast.loading({
          message: "上传中...",
          duration: 0,
        });
        let formData = new FormData();
        formData.append("LastFile", file);
        formData.append("FileName", file.name);
        this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
          Toast.clear();
          if (res.code === 200) {
            this.form.AccessoryPostList.push({
              name: file.name,
              osskey: res.data.osskey,
              uploadUrl: res.data.uploadUrl,
              url: res.data.url,
            });
            this.$toast.success(res.msg);
          } else {
            this.$toast.fail(res.msg || "操作失败");
            return false;
          }
        });
      }
    },
    tabNum(index) {
      this.currentIndex = index;
      // 切换按钮表单清空
      this.form.AccessoryPostList = [];
      this.form.Content = "";
    },
  },
};
</script>

<style lang="less" scoped>
.imgUpload {
  font-size: 20px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: border-box;
  width: 2.133333rem;
  height: 2.133333rem;
  margin: 0 0.213333rem 0.213333rem 0;
  background-color: #f7f8fa;
}
.evalute {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .topBars {
    width: 50%;
    margin: 0 auto;
    font-size: 14px;
    margin-top: 10px;
    height: 2%;
  }

  .my-card {
    margin-top: 10px;
    height: 100px;

    .top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid #ebedf0;

      .right {
        width: 36%;
        display: flex;
        justify-content: space-between;

        .icon {
          display: flex;
          align-items: center;
        }
      }
    }

    .content {
      margin-top: 10px;
      color: #a6a6a6;

      P {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
